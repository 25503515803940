export async function downloadImage(imagePath) {
  fetch(imagePath, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      Origin: window.location.origin,
    },
  })
    .then((resp) => resp.blob())
    .then((blob) => {
      if (!blob) {
        return;
      }
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = imagePath.split("/").pop();
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.error(err);
    });
}
