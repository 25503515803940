import{S3, config} from 'aws-sdk';


config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    region: process.env.REACT_APP_REGION
});

const s3 = new S3();

export const getPresignedUrl = async (bucket, key) => {
    try {
        const params = {
            Bucket: bucket,
            Key: key,
            Expires: 3600 // Expiration time in seconds (e.g., 1 hour)
        };
        const url = await s3.getSignedUrlPromise('getObject', params);
        return url;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};

export const uploadFileToS3 = async (file, bucket, key) => {
    try {
        const params = {
            Bucket: bucket,
            Key: key,
            Body: file
        };

        const uploadResult = await s3.upload(params).promise();
        return uploadResult;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};
export const getBucketObjectCount = async (bucketName,Prefix) => {
    try {
        const params = {
            Bucket: bucketName,
            Prefix:Prefix
        };

        const data = await s3.listObjectsV2(params).promise();

        // The total count of objects is available in the KeyCount property of the response
        return data.KeyCount;
    } catch (error) {
        console.error('Error getting bucket object count:', error);
        throw error;
    }
};
export const listImagesFromS3 = async (bucketName, key, nextContinuationToken = null, pageSize = 30) => {
    try {
        const params = {
            Bucket: bucketName,
            Prefix: key, // Assuming images are stored under this prefix
            ContinuationToken: nextContinuationToken,
            MaxKeys: pageSize // Limit the number of results per page
        };

        const data = await s3.listObjectsV2(params).promise();

        // Extract image keys from the response
        const imageKeys = data.Contents.map(obj => obj.Key);
        const imageUrls = await Promise.all(imageKeys.map(async (key) => {
            const urlParams = {
                Bucket: bucketName,
                Key: key,
                Expires: 3600 // URL expires in 1 hour (adjust as needed)
            };
            const url = await s3.getSignedUrlPromise('getObject', urlParams);
            return url;
        }));

        // If there are more results available, return the nextContinuationToken
        const nextToken = data.IsTruncated ? data.NextContinuationToken : null;

        return {
            imageUrls,
            token: nextToken
        };
    } catch (error) {
        console.error('Error listing images from S3:', error);
        throw error;
    }
};
