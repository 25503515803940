// Layout.jsx
import React from "react";
import Footer from "./pages/Footer";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import LoadingSpinner from "./components/Loading";
const Layout = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        paddingBottom: "100px",
        position: "relative",
      }}
    >
      <div>
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
