import { useEffect, useRef, useState } from "react";
import ImageLoader from "../assets/loader.gif";
import Play from "../assets/play-button.png";
import DefaultImage from "../assets/black-bg.jfif";
const LazyImage = ({
  placeholderSrc = ImageLoader,
  placeholderClassName = "img-fluid rounded object-fit-contain",
  placeholderStyle = { width: "31%" },
  src,
  alt,
  className,
  style,
  onClick,
  image,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState("");
  const placeholderRef = useRef(null);

  useEffect(() => {
    // Initiating Intersection Observer
    const observer = new IntersectionObserver(async (entries) => {
      // Set actual image source && unobserve when intersecting
      if (entries[0].isIntersecting) {
        try {
          if (image.thumbnailMediumPath || image.thumbnailPath) {
            const res = await fetch(
              `${
                image.thumbnailMediumPath || image.thumbnailPath
              }?v=${new Date().valueOf().toString()}`,
              {
                method: "HEAD",
              }
            );
            console.log(res);
            if (res.ok) {
              setView(image.thumbnailMediumPath || image.thumbnailPath);
            } else {
              setView(DefaultImage);
            }
          } else {
            setView(DefaultImage);
          }
        } catch (error) {
          setView(DefaultImage);
          console.log("error", error);
        } finally {
          setIsLoading(false);
        }

        observer.unobserve(placeholderRef.current);
      }
    });

    // observe for an placeholder image
    if (placeholderRef && placeholderRef.current) {
      observer.observe(placeholderRef.current);
    }
  }, [image]);

  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center m-auto ratio-1x1 h-100">
          <img
            src={placeholderSrc}
            alt=""
            className={placeholderClassName}
            width={"70"}
            ref={placeholderRef}
          />
        </div>
      )}

      <img
        src={view} // Gets src only when placeholder intersecting
        className={className}
        style={isLoading ? { display: "none" } : style}
        alt={alt}
        onLoad={() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        }}
        onClick={onClick}
      />

      {/* {image.type && image.type.includes("video") && (
        <div
          style={{
            background:
              "linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(48,48,48,1) 7%, rgba(255,255,255,0) 100%)",
            height: "-webkit-fill-available",
            width: "100%",
            position: "absolute",
          }}
        ></div>
      )} */}

      {image.type && image.type.includes("video") && (
        <img
          src={Play}
          className="position-absolute top-0 start-0  img-fluid rounded w-100 h-100"
          alt={`${image.image_name} vdo`}
          onClick={onClick}
          style={{
            cursor: "pointer",
            objectFit: "contain",
            objectPosition: "center",
            padding: "25%",
            zIndex: 2,
            display: isLoading ? "none" : "unset",
          }}
        />
      )}
    </>
  );
};
export default LazyImage;
