import React, { useEffect, useState } from "react";
import DownloadModal from "./DownloadModal";
import { downloadImage } from "../utils/download-image";
import SelectIcon from "../assets/icons/select.svg";
import DownloadIcon from "../assets/icons/download.svg";
import useSize from "../hooks/useSize";
import loadingGif from "../assets/loading-gif.gif";

const ImageGallery = ({
  images,
  isProcessing,
  isLoading,
  enableSelect,
  allSelected,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectMultiImages, setSelectMultiImages] = useState([]);
  const isMobile = useSize();

  const toggleImageSelection = (image_name) => {
    if (selectMultiImages.includes(image_name)) {
      setSelectMultiImages(
        selectMultiImages.filter((image) => image !== image_name)
      );
    } else {
      setSelectMultiImages([...selectMultiImages, image_name]);
    }
  };

  const handleClick = (index) => {
    setSelectedImage(index);
  };

  const handleDownload = async () => {
    // Download all selected images
    selectMultiImages.forEach(async (image) => {
      await downloadImage(image)
        .then(() => {
          console.log("downloaded");
          setSelectMultiImages([]);
          // setEnableSelect(false);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const goPrev = () => {
    if (images.length > selectedImage && selectedImage >= 1) {
      setSelectedImage(selectedImage - 1);
    }
  };

  const goNext = () => {
    if (images.length > selectedImage) {
      setSelectedImage(selectedImage + 1);
    }
  };

  useEffect(() => {
    console.log({ allSelected });
    if (allSelected) {
      setSelectMultiImages(images.map((image) => image.imagePath));
    } else {
      setSelectMultiImages([]);
    }
  }, [allSelected]);

  return (
    <>
      {isProcessing && (
        <>
          <div className="text-center">
            <img
              src={loadingGif}
              alt="Loading icon"
              width={250}
              height={100}
              className="img-fluid"
              style={{ margin: "auto" }}
            />
            <h2 className="text-center text-white">
              Sit tight, our AI is on a photo-finding mission!
            </h2>
          </div>
        </>
      )}
      {images.length == 0 && !isLoading && !isProcessing && (
        <h2 className="text-center m-5 text-white">No matches found</h2>
      )}

      {images.length && (
        <>
          <div
            className="d-flex justify-content-between px-2 position-sticky top-0 z-3 p-3"
            style={{ backgroundColor: "#1b1b1b" }}
          >
            <div className="text-white">Total Images: [{images.length}]</div>
            {/* <div
              onClick={() => {
                setEnableSelect(!enableSelect);
                setSelectMultiImages([]);
              }}
              className="cursor-pointer"
            >
              <img src={SelectIcon} alt="select icon" width={"25"} />
              {!isMobile && <span className="text-white ms-1">Select</span>}
            </div> */}
            {selectMultiImages.length > 0 && (
              <div className="cursor-pointer" onClick={handleDownload}>
                <img src={DownloadIcon} alt="download icon" width={"25"} />
                {!isMobile && <span className="text-white ms-1">Download</span>}
              </div>
            )}
          </div>
          <div
            className={`row row-cols-3 row-cols-sm-3 row-cols-xs-3 row-cols-md-4 row-cols-lg-4 g-1 my-4 pb-5`}
            style={{ position: "relative" }}
          >
            {images.map((image, index) => (
              <div key={index}>
                <div
                  className="aspect-ratio-container"
                  style={{
                    paddingBottom: "80%",
                    position: "relative",
                    backgroundColor: "#f0f0f0",
                    overflowBlock: "hidden",
                  }}
                >
                  {enableSelect && (
                    <div
                      style={{
                        zIndex: 1,
                        position: "absolute",
                        top: "-10px",
                        right: 0,
                        cursor: "pointer",
                        borderRadius: "4px",
                        backgroundColor: "",
                        margin: "auto",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        checked={selectMultiImages.includes(image.imagePath)}
                        onChange={() => toggleImageSelection(image.imagePath)}
                      />
                    </div>
                  )}

                  {image && (
                    <img
                      src={image.thumbnailPath}
                      onClick={() => {
                        if (enableSelect) {
                          toggleImageSelection(image.imagePath);
                        } else {
                          handleClick(index);
                        }
                      }}
                      className="position-absolute top-0 start-0 w-100 h-100"
                      alt={`user - ${index}`}
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {images[selectedImage] && (
        <DownloadModal
          key={`aimdl-${selectedImage}`}
          selectedImage={selectedImage}
          images={images}
          setSelectedImage={setSelectedImage}
          goNext={goNext}
          goPrev={goPrev}
        />
      )}
    </>
  );
};

export default ImageGallery;
