import { useEffect, useRef, useState } from "react";
import ImageLoader from "../assets/loader.gif";
import loadingGif from "../assets/loading-gif.gif";
export default function DownloadModal({
  selectedImage,
  images,
  setSelectedImage,
  goNext,
  goPrev,
}) {
  const [downloadingImages, setDownloadingImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState("");
  const placeholderRef = useRef(null);

  useEffect(() => {
    console.log(selectedImage);
    document.getElementsByTagName("body")[0].style = "overflow: hidden;";
    // Initiating Intersection Observer
    const observer = new IntersectionObserver((entries) => {
      // Set actual image source && unobserve when intersecting
      if (entries[0].isIntersecting) {
        setView(
          images[selectedImage].imagePath ||
            images[selectedImage].thumbnailMediumPath
        );
        observer.unobserve(placeholderRef.current);
      }
    });

    // observe for an placeholder image
    if (placeholderRef && placeholderRef.current) {
      observer.observe(placeholderRef.current);
    }
  }, [images, selectedImage]);

  const handleClose = () => {
    document.getElementsByTagName("body")[0].style = "overflow: auto;";
    setSelectedImage(null);
  };

  function downloadImage(image) {
    setDownloadingImages((prevState) => [...prevState, image.image_name]);
    fetch(image.imagePath, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Origin: window.location.origin,
      },
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        if (!blob) {
          return;
        }
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = image.image_name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        // alert(err)
        console.log(err);
        // let downloadLink = document.getElementById('download_' + image._id);
        // downloadLink.click();
      })
      .finally(() => {
        // setStartDownload(false)
        setDownloadingImages((prevState) =>
          prevState.filter((item) => item !== image.image_name)
        );
      });
  }

  return (
    <div
      className="modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content" style={{ minHeight: "80vh" }}>
          <div className="modal-header">
            <p className="modal-title" id="exampleModalLabel">
              {selectedImage + 1}|{images.length}
            </p>
            <button
              type="button"
              className="btn-close btn-default btn"
              onClick={handleClose}
              aria-label="Close"
            ></button>
          </div>
          <div
            className="modal-body text-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isLoading && (
              <div className="d-flex justify-content-center m-auto ratio-1x1 h-100 ">
                <img
                  src={loadingGif}
                  alt=""
                  className="img-fluid rounded object-fit-contain"
                  width={"270"}
                  ref={placeholderRef}
                />
              </div>
            )}
            {(!images[selectedImage].type ||
              images[selectedImage].type.includes("image")) && (
              <img
                src={view}
                style={{
                  maxHeight: "calc(100vh - 300px)",
                  display: isLoading ? "none" : "unset",
                }}
                onLoad={() => {
                  setTimeout(() => {
                    setIsLoading(false);
                  }, 100);
                }}
                className="img-fluid rounded shadow-lg"
                alt="Unsupported format"
              />
            )}
            {images[selectedImage].type &&
              images[selectedImage].type.includes("video") && (
                <video
                  autoPlay={true}
                  controls={true}
                  src={images[selectedImage].imagePath}
                  style={{
                    maxHeight: "calc(100vh - 300px)",
                    display: isLoading ? "none" : "unset",
                  }}
                  onLoadedData={() => {
                    console.log("Video data loaded");
                    setIsLoading(false);
                  }}
                  className="img-fluid rounded shadow-lg"
                  alt="Your browser doesn't support this video format"
                />
              )}
          </div>
          <div className="modal-footer d-flex justify-content-around">
            <button
              type="button"
              className="btn btn-secondary"
              disabled={selectedImage <= 0}
              onClick={goPrev}
            >
              <a className="icon icon__previous"></a>
            </button>
            <button
              className="btn btn-secondary"
              disabled={downloadingImages.includes(
                images[selectedImage].image_name
              )}
              onClick={() => downloadImage(images[selectedImage])}
            >
              {downloadingImages.includes(images[selectedImage].image_name)
                ? "Downloading"
                : "Download"}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              disabled={selectedImage + 1 == images.length}
              onClick={goNext}
            >
              <a className="icon icon__next"></a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
