import { useNavigate } from "react-router-dom";
import BackImg from "../assets/back-button.png";
export default function BackButton() {
  const navigate = useNavigate();
  return (
    <img
      src={BackImg}
      alt="Back Button"
      onClick={() => navigate(-1)}
      className="p-2 cursor-pointer"
      width={"40"}
    />
    // <button className="btn btn-xs btn-sm btn-outline-secondary bg-white text-black font-bold rounded-5">
    // </button>
  );
}
