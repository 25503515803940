import React, { useEffect, useState } from "react";
import defaultProfileImage from "../assets/profile.png";
import editIcon from "../assets/edit-icon.png";
import camera from "../assets/camera.png";
import { useNavigate } from "react-router-dom";
import { uploadFileToS3 } from "../utils/aws-s3";
import { globalEventEmitter } from "../utils/globalEventEmitter";
import "./Profile.css";
export default function Profile({ token, user, edit }) {
  const [filename, setFilename] = useState("");
  const [file, setFile] = useState("");
  const [isEdit, setIsEdit] = useState(!!edit);
  const [loading, setLoading] = useState(false);
  const [userProfileImage, setUserProfileImage] = useState("");
  const [presignedUrl, setPresignedUrl] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const navigate = useNavigate();
  const [isPhotoEdit, setIsPhotoEdit] = useState(false);

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/get-profile`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setName(data.user.name ? data.user.name : "");
        setFilename(data.user.profile ? data.user.profile : null);

        const url =
          process.env.REACT_APP_CLOUD_URL +
          user.phone.replace("+", "%2B") +
          "/profile/" +
          data.user.profile;

        if (data.user.profile) {
          setPresignedUrl(url);
          setUserProfileImage(url);
        }
      } else {
        localStorage.clear();
        navigate("/");
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      localStorage.clear();
      navigate("/");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!token) {
      localStorage.clear();
      navigate("/");
    } else {
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      fetchProfile();
    }
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    let filename = `${Date.now()}_${file.name}`;
    setFilename(filename);
    const reader = new FileReader();
    reader.onload = (e) => {
      const imageData = e.target.result;
      // Set the image data to the state or directly update the UI
      setPresignedUrl(imageData);
    };
    reader.readAsDataURL(file);
    setFile(file);
    setIsPhotoEdit(true);
  };

  const saveDetails = async (e) => {
    e.preventDefault();
    if (!name) {
      setNameError("name is required");
      return;
    }
    try {
      setLoading(true);
      if (file) {
        let dateTime = Date.now();
        const upload = await uploadFileToS3(
          file,
          process.env.REACT_APP_BUCKET,
          user.phone + "/profile/" + filename
        );
        const ai_image = await uploadFileToS3(
          file,
          process.env.REACT_APP_BUCKET,
          user.phone + "/ai_images/" + filename
        );
      }
      let body = {
        profileName: name, // Pass the OTP to the API
        profileImageName: filename,
      };
      // Make the POST request
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/update-profile`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(body),
        }
      );
      if (response.ok) {
        // Success
        const data = await response.json();
        if (data.user) {
          localStorage.setItem("user", JSON.stringify(data.user));
          globalEventEmitter.emit("profileUpdated", data.user);
          globalEventEmitter.emit("closeModal");
          navigate("/gallery");
          // if (edit) window.location.reload();
        }
      } else {
        // Handle error
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      setIsEdit(false);
      setIsPhotoEdit(false);
    }
  };

  return (
    <div className="container">
      <div className="row d-flex align-items-center justify-content-center h-100">
        <div className="col-12 text-center">
          <form onSubmit={saveDetails}>
            <div className="d-flex justify-content-center">
              <div
                className="text-center mb-4"
                style={{
                  position: "relative",
                  borderRadius: "50%",
                  overflow: "hidden",
                  maxWidth: "150px",
                }}
              >
                <img
                  key={file}
                  src={presignedUrl ? presignedUrl : defaultProfileImage}
                  alt="Profile"
                  className="rounded-circle img-fluid profile-img"
                />
                <label
                  className="position-absolute"
                  style={{
                    bottom: "0",
                    right: "0",
                    left: "0",
                    backgroundColor: "#FFF",
                    padding: "10px",
                    opacity: 0.8,
                  }}
                  htmlFor="profileImage"
                >
                  <img
                    src={camera}
                    style={{
                      cursor: "pointer",
                    }}
                    alt="camera-01"
                    width="24"
                    height="24"
                  />
                  <input
                    type="file"
                    id="profileImage"
                    className="visually-hidden"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </label>
              </div>
            </div>
            {!isEdit && (
              <div className="d-flex justify-content-center align-items-center">
                <h1 className="text-black text-ellipsis">{name}</h1>
                <img
                  src={editIcon}
                  alt="Edit Icon"
                  className="ms-2"
                  width={20}
                  height={20}
                  onClick={() => setIsEdit(true)}
                />
              </div>
            )}

            {isEdit && (
              <>
                <div className="form-outline">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    value={name}
                    onChange={handleChange}
                    placeholder="Your Full Name"
                  />
                  <div className="text-danger">{nameError}</div>
                </div>
              </>
            )}

            {(isEdit || isPhotoEdit) && (
              <div className="m-auto text-center mt-3">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary mb-2 btn-block me-2"
                >
                  {loading ? "Saving" : "Save"}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setPresignedUrl(userProfileImage);
                    setIsEdit(false);
                    setIsPhotoEdit(false);
                  }}
                  className="btn btn-secondary mb-2 btn-block"
                >
                  Cancel
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
