import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import loadingGif from "../assets/zip-folder.gif";
import folderSvg from "../assets/view.png";
import downloadSvg from "../assets/download-svg.svg";
import BackButton from "../components/BackButton";
import { showAlert } from "../utils/custom-alert";
import "./Download.css";
import LoadingSpinner from "../components/Loading";
import Download from "../assets/download-svg-green.svg";
import SearchIcon from "../assets/search-icon.png";
import CopyRight from "../components/CopyRight";
import deleteIcon from "../assets/delete.svg";
import deleteRedIcon from "../assets/deleteRed.svg";

export default function DownloadPage() {
  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const handleChange = (event) => {
    setSearchKeyword(event.target.value.toLowerCase());
  };

  useEffect(() => {
    if (searchKeyword) {
      const filtered = users.filter((user) => {
        return (
          user.name?.toLowerCase().includes(searchKeyword) ||
          user.phone?.toLowerCase().includes(searchKeyword)
        );
      });
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  }, [searchKeyword]);

  const [message, setMessage] = useState(
    "Please wait, we are creating a zip file."
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadAll, setIsDownloadAll] = useState(false);
  const [isDownloadAllLoading, setIsDownloadAllLoading] = useState(false);
  const token = location.state && location.state.token;

  const toggleUserSelection = (phone) => {
    if (selectedUsers.includes(phone)) {
      setSelectedUsers(selectedUsers.filter((item) => item !== phone));
      if (selectedUsers.length - 1 > 0) {
        setIsDownloadAll(true);
      } else {
        setIsDownloadAll(false);
      }
    } else {
      setSelectedUsers([...selectedUsers, phone]);
      if (selectedUsers.length + 1 > 0) {
        setIsDownloadAll(true);
      } else {
        setIsDownloadAll(false);
      }
    }
  };

  const selectAllUser = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
      setIsDownloadAll(false);
    } else {
      setSelectedUsers(users.map((user) => user.phone));
      setIsDownloadAll(true);
    }
  };

  async function downloadFile(files) {
    try {
      setIsLoading(true);
      if (files.length > 1) {
        setIsDownloadAllLoading(true);
      }
      const body = {
        bucket: `${process.env.REACT_APP_BUCKET}`,
        folders: files,
      };
      const response = await fetch(
        `https://test-wedding-api.deepfoods.com/download-all`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();
      const zipUrl = data.data;
      // setMessage(data.message);

      const interval = setInterval(async () => {
        try {
          await fetch(`${zipUrl}?v=${new Date().valueOf().toString()}`, {
            method: "HEAD",
          });
          clearInterval(interval);

          setTimeout(() => {
            downloadFromURL(zipUrl);
            setIsLoading(false);
            setIsDownloadAll(false);
            setIsDownloadAllLoading(false);
            setSelectedUsers([]);
          }, 5000);
        } catch (error) {
          console.log("error", error);
        }
      }, 5000);
      // Verify zipUrl using HEAD request
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const downloadFromURL = (url) => {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = url;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const [isFetching, setIsFetching] = useState(true);

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/users`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const data = await response.json();
      // setUsers(data.data.filter((user) => user.name !== undefined)); // Filter users only with name
      setUsers(data.data); // Filter users only with name

      // Sort users by name
      const sortedUsers = data.data.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        } else if (a.name) {
          return -1;
        } else if (b.name) {
          return 1;
        }
      });

      setFilteredUsers(sortedUsers); // Filter users only with name
      // setFilteredUsers(data.data.filter((user) => user.name !== undefined)); // Filter users only with name
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  async function deleteUserAPI(ids) {
    const response = await fetch(
              `${process.env.REACT_APP_API_URL}/admin/users/delete`, // Adjust the endpoint as necessary
              {
                method: "PUT", // Use PUT method for deletion
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token, // Ensure you have the token available
                },
                body: JSON.stringify({ ids }), // Send the array of IDs in the request body
              }
            );

            if (!response.ok) {
              throw new Error("Failed to delete users");
            }

            const data = await response.json();

            const remainingUser = users.filter((user) => !ids.includes(user.id));

            setUsers(remainingUser);
            setFilteredUsers(remainingUser);
  }

  const deleteUser = async (ids) => {
    const buttons = [
      {
        label: "Ok",
        onClick: async () => {
          await deleteUserAPI(ids);
        },
      },
      {
        label: "Cancel",
        onClick: () => {
          return;
        },
      },
    ];
    showAlert(
      "Are you sure you want to delete this user and its image/video?",
      buttons
    );
  };

  const deleteUsers = async () => {
    const buttons = [
      {
        label: "Ok",
        onClick: async () => {
          const ids = users.filter(user => selectedUsers.includes(user.phone)).map(user => user.id) 
          await deleteUserAPI(ids).then(res => {
            setSelectedUsers([]);
            setIsLoading(false);
            setIsDownloadAll(false);
            setIsDownloadAllLoading(false);
          });
        },
      },
      {
        label: "Cancel",
        onClick: () => {
          return;
        },
      },
    ];
    showAlert(
      "Are you sure you want to delete selected users and its image/video?",
      buttons
    );
  };

  return (
    <>
      <div className="container">
        <div
          className="navbar navbar-light justify-content-between p-2 position-sticky top-0 z-3"
          style={{ backgroundColor: "#1b1b1b" }}
        >
          <BackButton />
          <div className="search-box">
            <img src={SearchIcon} alt="Search Icon" width={"20"} />

            <input
              type="text"
              placeholder="search"
              className="border-0 search-input"
              value={searchKeyword}
              onKeyUp={handleChange}
              onChange={handleChange}
            />

            {searchKeyword && (
              <span
                className="cursor-pointer search-cancel-icon"
                onClick={() => setSearchKeyword("")}
              >
                X
              </span>
            )}
          </div>
          {isDownloadAll && selectedUsers.length !== 0 ? (
            <>
              <button
                title="Download all images/videos"
                disabled={isDownloadAllLoading}
                onClick={() => {
                  downloadFile(selectedUsers);
                }}
                className="btn btn-xs btn-sm  font-bold rounded download-all cursor-pointer"
              >
                <img
                  src={Download}
                  width={20}
                  style={{ cursor: "pointer" }}
                  alt={"download all images"}
                />
                <p className="mb-0">
                  {isMobile ? "Selected" : "Download Selected"}
                </p>
              </button>
              <button
                title="Delete all users"
                disabled={isDownloadAllLoading}
                onClick={() => {
                  deleteUsers();
                }}
                className="btn btn-xs btn-sm  font-bold rounded delete-all cursor-pointer"
              >
                <img
                  src={deleteRedIcon}
                  width={20}
                  style={{ cursor: "pointer" }}
                  alt={"download all images"}
                />
                <p className="mb-0">
                  {isMobile ? "Selected" : "Delete Selected"}
                </p>
              </button>
            </>
          ) : (
            <div
              style={{ width: "20%", height: "100%" }}
              className="btn btn-xs btn-sm  font-bold rounded download-all cursor-pointer p-3"
            >
              <img
                src={Download}
                width={20}
                style={{ display: "none" }}
                alt={"download all images"}
              />
              <p className="mb-0">&nbsp;</p>
            </div>
          )}
        </div>

        <div className="" style={{ color: "#fff" }}>
          <div className="loading text-center m-1">
            {!isLoading && <>&nbsp;</>}
            {isLoading && (
              <>
                <img
                  src={loadingGif}
                  alt="Loading..."
                  width={28}
                  className="rounded"
                />
                <span className="ms-2">{message}</span>
              </>
            )}
          </div>
        </div>

        <div
          className="d-flex m-auto justify-content-center p-auto download-page table-responsive fixTableHead"
          style={{ color: "#fff", maxHeight: "80vh" }}
        >
          <table className="table table-dark overflow-auto">
            <thead>
              <tr>
                <th width={"5%"}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    disabled={isLoading}
                    checked={
                      users.length !== 0 &&
                      selectedUsers.length === users.length
                    }
                    onChange={selectAllUser}
                  />
                </th>
                <th width={"30%"}>Name</th>
                <th width={"30%"}>Phone Number</th>
                <th width={"15%"}>Files</th>
                <th width={"20%"} className="text-center">
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr key={"000"}>
                  <td colSpan={4} style={{ borderBottom: "none" }}>
                    <div className="d-flex justify-content-center m-auto">
                      <LoadingSpinner />
                    </div>
                  </td>
                </tr>
              )}
              {!isFetching && filteredUsers.length === 0 && (
                <tr key={"000"}>
                  <td colSpan={4} className="text-center">
                    No Result Found
                  </td>
                </tr>
              )}
              {filteredUsers.map((user) => (
                <tr key={user.phone}>
                  <td width={"5%"}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="btncheck1"
                      disabled={isLoading}
                      checked={selectedUsers.includes(user.phone)}
                      onChange={() => toggleUserSelection(user.phone)}
                    />
                  </td>
                  <td width={"30%"}>
                    <p className="text-ellipsis mb-0 download-username">
                      {isMobile ? (
                        <NavLink
                          to={`/${user.phone}/gallery?name=${
                            user.name ?? user.phone
                          }`}
                          className="username"
                        >
                          {user.name ?? user.phone}
                        </NavLink>
                      ) : (
                        user.name ?? user.phone
                      )}
                    </p>
                  </td>
                  <td width={"30%"}>{user.phone}</td>
                  <td width={"15%"}>{user.documentCount}</td>
                  <td width={"20%"} className="text-center">
                    {!isMobile && (
                      <NavLink
                        to={`/${user.phone}/gallery?name=${
                          user.name ?? user.phone
                        }`}
                      >
                        <img src={folderSvg} alt={`folder svg`} width={18} />
                      </NavLink>
                    )}
                    <span className="cursor-pointer"></span>
                    <span
                      className="cursor-pointer ms-0 ms-md-2"
                      onClick={() => {
                        if (!isLoading) {
                          setIsLoading(true);
                          downloadFile([user.phone]);
                        } else
                          showAlert(
                            "Please wait for the zip file to be created."
                          );
                      }}
                    >
                      <img src={downloadSvg} alt={`download svg`} width={18} />
                    </span>

                    <span
                      className="cursor-pointer ms-0 ms-md-2"
                      onClick={() => {
                        deleteUser([user.id]);
                      }}
                    >
                      <img
                        src={deleteIcon}
                        width={16}
                        alt="delete icon"
                        className="mb-1 object-fit-cover"
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <CopyRight />
    </>
  );
}
