export function intersection(element, callback) {
  const options = {
    threshold: 0.2,
  };
  const observer = new IntersectionObserver(function (entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        callback();
        observer.unobserve(element); // Removeing oberserver of the element
      }
    });
  }, options);

  observer.observe(element);
}
