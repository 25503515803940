import React from 'react';

const Footer = React.memo(() => {
  return (
    <ul className="nav justify-content-center navbar-light w-100 z-3" style={{position:'fixed',bottom:0, backgroundColor:'#1b1b1b'}}>
      <li className="nav-item">
        <a className="nav-link disabled" href="#" tabIndex="-1" aria-disabled="true">Powered by Deepfoods IT Team.</a>
      </li>
    </ul>
  );
});

export default Footer;
