/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import SingleCol from "../assets/single.svg";
import MultiCol from "../assets/icons/Multi.svg";
import Download from "../assets/icons/download.svg";
import AiSearch from "../assets/icons/ai.svg";
import LogoutIcon from "../assets/icons/logout.svg";
import SelectIcon from "../assets/icons/select.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import defaultProfileImage from "../assets/profile.png";
import { showAlert } from "../utils/custom-alert";
import BackButton from "../components/BackButton";
import useSize from "./../hooks/useSize";
import { globalEventEmitter } from "../utils/globalEventEmitter";

const Navbar = React.memo((props) => {
  const [multiListIcon, setMultiListIcon] = useState(
    localStorage.getItem("multiList") || "yes"
  );
  let token = localStorage.getItem("token");

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [profileUrl, setprofileUrl] = useState(null);
  const [Fetchingprofile, setFetchingprofile] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const userName = new URLSearchParams(location?.search).get("name");
  const isMobile = useSize();
  // const menuClasses = isMobile
  //   ? "w-75 d-flex justify-content-between"
  //   : "w-25 d-flex justify-content-between";

  const logout = () => {
    const buttons = [
      {
        label: "Ok",
        onClick: () => {
          localStorage.clear();
          navigate("/");
        },
      },
      {
        label: "Cancel",
        onClick: () => {
          return;
        },
      },
    ];
    showAlert("Are you sure you want to logout ?", buttons);
  };

  useEffect(() => {
    fetchProfile();

    globalEventEmitter.on("profileUpdated", (data) => {
      setUser(data);
      if (data.profile) {
        const url =
          process.env.REACT_APP_CLOUD_URL +
          data.phone.replace("+", "%2B") +
          "/profile/" +
          data.profile;
        setprofileUrl(url);
      }
    });
  }, []);

  const fetchProfile = async () => {
    setFetchingprofile(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/get-profile`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        // if(data.user.name){
        //     navigate('/gallery')
        // }

        if (data.user.profile) {
          const url =
            process.env.REACT_APP_CLOUD_URL +
            user.phone.replace("+", "%2B") +
            "/profile/" +
            data.user.profile;
          setprofileUrl(url);
        }
      } else {
        localStorage.clear();
        navigate("/");
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      localStorage.clear();
      navigate("/");
      console.error("Error:", error);
    } finally {
      setFetchingprofile(false);
    }
  };

  const changeListStyle = (value) => {
    setMultiListIcon(value);
    props.setMultiList(value);
    localStorage.setItem("multiList", value);
  };

  return (
    <nav
      className="navbar navbar-light justify-content-between position-sticky top-0 z-3"
      style={{
        backgroundColor: "#1b1b1b",
        padding: "15px 10px",
        borderBottom: "1px solid #8f8f8f",
      }}
    >
      <div className="container d-flex align-items-center">
        {props.phone ? (
          <>
            <div className="d-flex align-items-center">
              <BackButton />

              <label className="text-white ms-2" style={{ width: "100%" }}>
                {userName}
              </label>
            </div>
          </>
        ) : (
          <>
            <div
              className="circle"
              style={{ cursor: "pointer" }}
              onClick={() => props.openProfile()}
            >
              <img
                src={profileUrl ? profileUrl : defaultProfileImage}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                alt="profile"
              />
            </div>
          </>
        )}

        {!props.phone && (
          <>
            {props.images?.length > 0 && (
              <div
                onClick={() => {
                  props.setEnableSelect(!props.enableSelect);
                  props.setSelectMultiImages([]);
                }}
                className="cursor-pointer"
              >
                <img src={SelectIcon} alt="select icon" width={"25"} />
                {!isMobile && <span className="text-white ms-1">Select</span>}
              </div>
            )}

            <NavLink title="AI Powered media search" to={"/ai-search"}>
              <img
                src={AiSearch}
                width={35}
                style={{ cursor: "pointer" }}
                alt={"download all images"}
              />
              {!isMobile && <span className="text-white ms-1">AI Search</span>}
            </NavLink>

            {user?.is_admin && (
              <NavLink title="Download all images/videos" to={"/download"}>
                <img
                  src={Download}
                  width={27}
                  style={{ cursor: "pointer" }}
                  alt={"download all images"}
                />
                {!isMobile && <span className="text-white ms-1">Download</span>}
              </NavLink>
            )}
          </>
        )}
        {props.images?.length > 0 && (
          <div
            className="cursor-pointer"
            onClick={() =>
              changeListStyle(multiListIcon === "yes" ? "no" : "yes")
            }
          >
            <img
              src={multiListIcon === "yes" ? MultiCol : SingleCol}
              width={27}
              style={{ cursor: "pointer" }}
              alt={"grid/list"}
            />
            {!isMobile && (
              <span className="text-white ms-1">
                {multiListIcon === "yes" ? "Small Tiles" : "Big Tiles"}
              </span>
            )}
          </div>
        )}

        {!props.phone && (
          <img
            src={LogoutIcon}
            width={25}
            style={{ cursor: "pointer" }}
            alt={"logout"}
            onClick={logout}
          />
        )}
      </div>
    </nav>
  );
});

export default Navbar;
