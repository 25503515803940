import Login from './pages/Login'
import './index.css';
function App() {

  return (
    <Login />
  )
}

export default App
