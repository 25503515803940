// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./Layout";
import App from "./App.jsx";
import Profile from "./pages/Profile.jsx";
import Home from "./pages/Home.jsx";
// import AISearch from "./pages/UsersListAi.jsx";
import UsersListAi from "./pages/UsersListAi.jsx";
import AISearches from "./pages/AISearches.jsx";
import Download from "./pages/Download.jsx";
import UserGallery from "./pages/UserGallery.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Router>
    <Routes path="/" element={<Layout />}>
      <Route index element={<App />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/gallery" element={<Home />} />
      <Route path="/ai-search" element={<UsersListAi />} />
      <Route path="/search-results" element={<AISearches />} />
      <Route path="/download" element={<Download />} />
      <Route path="/:phone/gallery" element={<Home />} />
    </Routes>
  </Router>
  // </React.StrictMode>
);
