import React, { useEffect, useState } from "react";
import addSearch from "../assets/add-search.png";
import { useNavigate } from "react-router-dom";
import { uploadFileToS3 } from "../utils/aws-s3";
import deleteIcon from "../assets/delete.svg";
import BackButton from "../components/BackButton";
import { showAlert } from "../utils/custom-alert";
import CopyRight from "../components/CopyRight";
import ImageGallery from "../components/ImageGallery";

export default function UsersListAi() {
  let user = JSON.parse(localStorage.getItem("user"));
  let token = localStorage.getItem("token");
  const [isFetching, setIsFetching] = useState(false);
  const [enableSelect, setEnableSelect] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  // const [selectMultiImages, setSelectMultiImages] = useState([]);
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();

  const UploadImages = async (item) => {
    // Create a hidden file input element
    const fileInput = document.getElementById("fileInput");
    fileInput.value = "";
    fileInput.click();
  };

  const deleteImages = async () => {
    const buttons = [
      {
        label: "Ok",
        onClick: async () => {
          const deleteImageName = users.find(
            (item) => item._id === currentSelectedAi
          );
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/ai-search/delete-photos`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
              body: JSON.stringify({ images: deleteImageName.image_name }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to save image data");
          } else {
            let imgs = users.filter(
              (item) => deleteImageName.image_name !== item.image_name
            );
            setCurrentSelectedAi(null);
            setCurrentIsProcessing(false);
            setUsers(imgs);
            // setSelectMultiImages([]);
            setEnableSelect(false);
          }
        },
      },
      {
        label: "Cancel",
        onClick: () => {
          return;
        },
      },
    ];
    showAlert("Are you sure you want to delete this image?", buttons);
  };

  const handleFileUpload = async (e) => {
    const selectedFiles = Array.from(e.target.files);

    setIsFetching(true);
    let filename = `${Date.now()}_${selectedFiles[0].name}`;
    const key = `${user.phone}/ai_images/${filename}`;
    await uploadFileToS3(selectedFiles[0], process.env.REACT_APP_BUCKET, key);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/ai-search/add-image`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          imageName: filename,
          type: selectedFiles[0].type,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to save image data");
    } else {
      const jsonResponse = await response.json();
      console.log(jsonResponse);

      setUsers((prevUsers) => [...prevUsers, jsonResponse.data]);
    }
    setIsFetching(false);
  };

  const fetchImages = async () => {
    try {
      setIsFetching(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/ai-search/get-photos`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        localStorage.clear();
        navigate("/");
        throw new Error("Failed to fetch images from server");
      }

      const data = await response.json();
      // Update the images state with fetched images
      setUsers(data.data);
    } catch (error) {
      console.error("Error fetching images:", error);
      localStorage.clear();
      navigate("/");
    } finally {
      setIsFetching(false);
    }
  };

  const [aiImages, setAiImages] = useState([]);
  const [cloneAiImages, setCloneAiImages] = useState([]);

  const fetchAiImages = async () => {
    try {
      setIsFetching(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/ai-search/get-all-searches`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        localStorage.clear();
        navigate("/");
        throw new Error("Failed to fetch images from server");
      }

      const data = await response.json();
      // Update the images state with fetched images
      setAiImages(data.data);
      setCloneAiImages(data.data);
    } catch (error) {
      console.error("Error fetching images:", error);
      localStorage.clear();
      navigate("/");
    } finally {
      setIsFetching(false);
    }
  };

  const [currentSelectedAi, setCurrentSelectedAi] = useState(null);
  const [currentIsProcessing, setCurrentIsProcessing] = useState(false);
  function filterAiImages(id) {
    console.log({ currentSelectedAi });
    if (id) {
      const filtered = cloneAiImages.filter((item) => item.ai_id === id);
      setAiImages(filtered);
    } else {
      fetchAiImages(cloneAiImages);
    }
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      navigate("/");
    } else {
      fetchImages();
      fetchAiImages();
    }
  }, []);

  async function checkProcessed() {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/ai-search/get-photos`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({}),
      }
    );

    const data = await response.json();

    const isProcessed = data.data.filter(
      (item) => item._id === currentSelectedAi
    )[0];

    setCurrentIsProcessing(!isProcessed.is_processed);
    return isProcessed.is_processed;
  }

  useEffect(() => {
    if (currentIsProcessing) {
      const interval = setInterval(async () => {
        try {
          const isProcessing = await checkProcessed();
          console.log({ isProcessing });
          if (isProcessing) {
            clearInterval(interval);
          }
        } catch (error) {
          console.log("error", error);
        }
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [currentIsProcessing]);

  // const toggleImageSelection = (image_name) => {
  //   if (selectMultiImages?.includes(image_name)) {
  //     setSelectMultiImages(
  //       selectMultiImages.filter((image) => image !== image_name)
  //     );
  //   } else {
  //     setSelectMultiImages([...selectMultiImages, image_name]);
  //   }
  // };

  return (
    <>
      <div className="container">
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(e) => handleFileUpload(e)}
          id="fileInput"
          capture
          accept="image/*"
        />
        <div
          className="navbar navbar-light justify-content-between position-sticky top-0 z-3"
          style={{ backgroundColor: "#1b1b1b", padding: "13px 8px" }}
        >
          <BackButton />

          {users?.length > 0 && (
            <>
              <div className="d-flex gap-2">
                <button
                  onClick={() => {
                    setEnableSelect(!enableSelect);
                    setAllSelected(!allSelected);
                    // setSelectMultiImages([]);
                  }}
                  className="btn btn-xs btn-sm btn-outline-secondary bg-white text-black font-bold rounded-5"
                >
                  Select All
                </button>
                <button
                  onClick={() => {
                    setEnableSelect(!enableSelect);
                    // setSelectMultiImages([]);
                  }}
                  className="btn btn-xs btn-sm btn-outline-secondary bg-white text-black font-bold rounded-5"
                >
                  Select Image
                </button>
              </div>
            </>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center text-white text-center">
          <b>Add Family Members,&nbsp;Click on Pic For AI Search</b>
        </div>

        <div className="container py-3 mt-3 border-bottom border-top">
          <div className="d-flex justify-content-center">
            {isFetching && (
              <div
                className="spinner-border text-light position-absolute top-3"
                role="status"
                style={{ zIndex: 999999 }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </div>
          {currentSelectedAi && (
            <div className="d-flex justify-content-end rounded-circle cursor-pointer">
              <button
                className="btn btn-sm btn-danger rounded-5 text-center"
                onClick={() => deleteImages()}
              >
                <img
                  src={deleteIcon}
                  width={16}
                  alt="delete icon"
                  className="mb-1 object-fit-cover"
                />{" "}
                Selfie
                {/* {selectMultiImages.length > 0 &&
                  `(${selectMultiImages.length})`} */}
              </button>
            </div>
          )}
          <div className="d-flex flex-wrap justify-content-start mx-auto gap-4">
            <div
              className="rounded-circle cursor-pointer d-flex align-items-center"
              style={{
                // width: "calc(100% / 4 - 20px)",
                maxWidth: "60px",
                height: "60px",
                position: "relative",
              }} // For mobile devices, 4 items per row
            >
              <img
                className={`object-fit-cover cursor-pointer`}
                style={{ borderRadius: "50%", width: "45px", height: "45px" }}
                src={addSearch}
                alt={`Select User`}
                onClick={() => UploadImages()}
              />
            </div>
            {users.map((user, index) => (
              <div
                key={index}
                className={`rounded-circle cursor-pointer ${
                  currentSelectedAi === user._id
                    ? "border border-3 border-white"
                    : ""
                }`}
                style={{
                  // width: "calc(100% / 4 - 20px)",
                  width: "60px",
                  height: "60px",
                  position: "relative",
                }} // For mobile devices, 4 items per row
              >
                {/* {enableSelect &&
                  !user.thumbnailPath?.includes("data:image/png") && (
                    <div
                      style={{
                        zIndex: 1,
                        position: "absolute",
                        top: -5,
                        right: 5,
                        cursor: "pointer",
                        borderRadius: "4px",
                        backgroundColor: "",
                        margin: "auto",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        checked={selectMultiImages?.includes(user.image_name)}
                        onChange={() => toggleImageSelection(user.image_name)}
                      />
                    </div>
                  )} */}

                <img
                  className={`w-100 h-100 object-fit-cover ${
                    user.thumbnailPath?.includes("data:image/png")
                      ? "cursor-pointer"
                      : ""
                  }`}
                  style={{ borderRadius: "50%" }}
                  src={user.thumbnailPath ?? user.imagePath}
                  alt={`User ${index}`}
                  onClick={() => {
                    if (currentSelectedAi !== user._id) {
                      setCurrentSelectedAi(user._id);
                      setCurrentIsProcessing(!user.is_processed);
                      filterAiImages(user._id);
                    } else {
                      setCurrentSelectedAi(null);
                      setCurrentIsProcessing(false);
                      setAiImages(cloneAiImages);
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <ImageGallery
          key={currentSelectedAi}
          images={aiImages}
          isProcessing={currentIsProcessing}
          enableSelect={enableSelect}
          allSelected={allSelected}
        />
      </div>
      <CopyRight />
    </>
  );
}
