import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { uploadFileToS3 } from "../utils/aws-s3";
import plus from "../assets/plus.png";
import deleteIcon from "../assets/delete.svg";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { intersection } from "../utils/intersection";
import LoadingSpinner from "../components/Loading";
import LazyImage from "../utils/lazy-image";
import DownloadModal from "../components/DownloadModal";
import { showAlert } from "../utils/custom-alert";
import useSize from "../hooks/useSize";
import { Modal, Offcanvas } from "bootstrap";
import Profile from "./Profile";
import { globalEventEmitter } from "../utils/globalEventEmitter";
import CopyRight from "../components/CopyRight";
export default function ImageGallery() {
  const { phone } = useParams();
  let location = useLocation();

  let user = JSON.parse(localStorage.getItem("user"));
  let token = localStorage.getItem("token");
  let limit = 20;

  const isNewUser = new URLSearchParams(location?.search).get("join");
  const [selectedImage, setSelectedImage] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [initialLoaded, setInitialLoaded] = useState(false);

  const [images, setImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState("0/0");
  const [fileUploadProgress, setFileUploadProgress] = useState(0);

  const [enableSelect, setEnableSelect] = useState(false);
  const [selectMultiImages, setSelectMultiImages] = useState([]);

  const [multiList, setMultiList] = useState(
    localStorage.getItem("multiList") || "yes"
  );

  const toggleImageSelection = (image_name) => {
    if (selectMultiImages.includes(image_name)) {
      setSelectMultiImages(
        selectMultiImages.filter((image) => image !== image_name)
      );
    } else {
      setSelectMultiImages([...selectMultiImages, image_name]);
    }
  };

  const navigate = useNavigate();

  const UploadImages = async () => {
    // Create a hidden file input element
    const fileInput = document.getElementById("fileInput");
    fileInput.value = "";
    fileInput.click();
  };

  const fetchImages = async () => {
    try {
      if (isFetching) {
        return;
      }
      setIsFetching(true);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/get-photos?phone=${encodeURIComponent(
          phone ?? user.phone
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            limit: limit,
            page: page,
          }),
        }
      );

      if (!response.ok) {
        localStorage.clear();
        navigate("/");
        throw new Error("Failed to fetch images from server");
      }

      const data = await response.json();

      setHasMore(data.images.length === limit);

      // Update the images state with fetched images
      if (images.length === data.totalImages) {
      } else {
        if (page === 1) {
          setImages([]);
        }
        setImages((prevImages) => [...prevImages, ...data.images]);
      }

      setPage(Math.ceil(images.length / limit) + 1);
    } catch (error) {
      console.error("Error fetching images:", error);
      // alert("Something went wrong");
      localStorage.clear();
      navigate("/");
    } finally {
      setIsFetching(false);
      setInitialLoaded(true);
    }
  };

  const [bsOffcanvas, setBsOffcanvas] = useState(null);
  const [bsModal, setBsModal] = useState(null);

  async function setModals() {
    if (isMobile) {
      var myOffcanvas = document.getElementById("profileTopCanvas");
      setBsOffcanvas(new Offcanvas(myOffcanvas));
    } else {
      var myModal = document.getElementById("profileModal");

      setBsModal(
        new Modal(myModal, {
          backdrop: isNewUser ? "static" : "true",
        })
      );
    }
  }

  useEffect(() => {
    setModals();
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      navigate("/");
    } else {
      fetchImages();
    }

    globalEventEmitter.on("closeModal", () => {
      console.log("received");
      closeProfile();
    });
  }, []);

  useEffect(() => {
    if (isNewUser) {
      setTimeout(() => {
        openProfile();
      }, 1000);
    }
  }, [bsModal, bsOffcanvas]);

  useEffect(() => {
    if (images.length > 0) {
      const sections = document.getElementById(`last-element${images.length}`);
      if (hasMore) {
        intersection(sections, () => {
          fetchImages();
        });
      }
      setIsUploading(true);
    } else {
      setIsFetching(false);
      setIsUploading(false);
    }
    console.log(images);
  }, [images]);

  const handleFileUpload = async (e) => {
    const selectedFiles = Array.from(e.target.files);

    setLoading(true);
    setIsUploading(true);
    setFileUploaded(`0/${selectedFiles.length}`);
    setFileUploadProgress((0 / selectedFiles.length) * 100);
    // Define batch size (e.g., number of files to process in parallel)
    const batchSize = 5; // Adjust as needed

    // Calculate number of batches
    const numBatches = Math.ceil(selectedFiles.length / batchSize);

    let newFiles = [];
    // Function to upload a batch of files
    const uploadBatch = async (batchfiles) => {
      // Map each file to an upload promise
      const uploadPromises = batchfiles.map(async (file) => {
        try {
          // Construct the key for the file in S3 (e.g., using a timestamp or UUID to ensure uniqueness)
          let filename = `${Date.now()}_${file.name}`;
          const key = `${user.phone}/images/${filename}`;

          // Upload the file to S3
          await uploadFileToS3(file, process.env.REACT_APP_BUCKET, key);
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/add-images`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
              body: JSON.stringify({ imageName: filename, type: file.type }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to save image data");
          } else {
            const jsonResponse = await response.json();

            newFiles.push(jsonResponse.data);

            setFileUploaded(`${newFiles.length}/${selectedFiles.length}`);
            setFileUploadProgress(
              (newFiles.length / selectedFiles.length) * 100
            );
            console.log(newFiles);
            setImages((prevImages) => [...prevImages, jsonResponse.data]);
          }
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      });

      // Wait for all uploads in the batch to complete
      await Promise.all(uploadPromises);
    };

    setLoading(true);
    // Process batches sequentially
    for (let i = 0; i < numBatches; i++) {
      const startIdx = i * batchSize;
      const endIdx = Math.min((i + 1) * batchSize, selectedFiles.length);
      const batch = selectedFiles.slice(startIdx, endIdx);

      // Upload the current batch
      await uploadBatch(batch);
    }

    setLoading(false);
    setFileUploaded("");
  };

  const handleClick = (index) => {
    setSelectedImage(index);
  };

  const deleteImages = async () => {
    const buttons = [
      {
        label: "Ok",
        onClick: async () => {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/delete-photos`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
              body: JSON.stringify({ images: selectMultiImages }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to save image data");
          } else {
            let imgs = images.filter(
              (item) =>
                !selectMultiImages.find(
                  (selectedImage) => selectedImage === item.image_name
                )
            );
            setImages(imgs);

            if (images.length <= limit && hasMore) {
              setPage(Math.ceil(images.length / limit) + 1);
              // !isFetching && !isUploading;
              fetchImages();
            }
            setSelectMultiImages([]);
            setEnableSelect(false);
          }
        },
      },
      {
        label: "Cancel",
        onClick: () => {
          return;
        },
      },
    ];
    showAlert("Are you sure you want to delete this image/video?", buttons);
  };

  const goPrev = () => {
    if (images.length > selectedImage && selectedImage >= 1) {
      setSelectedImage(selectedImage - 1);
    }
  };

  const goNext = () => {
    if (images.length > selectedImage) {
      setSelectedImage(selectedImage + 1);
    }

    if (selectedImage === images.length - 6) {
      setPage(Math.ceil(images.length / limit) + 1);
      fetchImages();
    }
  };

  const selectAllImages = () => {
    if (selectMultiImages.length === images.length) {
      setSelectMultiImages([]);
    } else {
      setSelectMultiImages(images.map((img) => img.image_name));
    }
  };

  const isMobile = useSize();
  const openProfile = () => {
    if (isMobile) {
      if (bsOffcanvas) bsOffcanvas.show();
    } else {
      if (bsModal) bsModal.show();
    }
  };

  const closeProfile = () => {
    if (isMobile) {
      if (bsOffcanvas) bsOffcanvas.hide();
    } else {
      if (bsModal) bsModal.hide();
    }
  };

  return (
    <>
      <div>
        <Navbar
          phone={phone}
          images={images}
          openProfile={openProfile}
          setSelectMultiImages={setSelectMultiImages}
          selectMultiImages={selectMultiImages}
          setEnableSelect={setEnableSelect}
          setMultiList={setMultiList}
          enableSelect={enableSelect}
        />

        {isMobile ? (
          <div
            className="offcanvas offcanvas-top"
            data-bs-scroll="false"
            data-bs-backdrop={isNewUser ? "static" : "true"}
            style={{
              backdropFilter: "blur(30px) !important",
              background: "rgb(255, 255, 255, 0.85)",
              height: "fit-content",
              borderBottomLeftRadius: "30px",
              borderBottomRightRadius: "30px",
            }}
            id="profileTopCanvas"
          >
            <div className="w-100 pe-1 pt-1">
              {!isNewUser && (
                <button
                  type="button"
                  className="flex-1 btn-close text-reset float-end"
                  aria-label="Close"
                  onClick={() => closeProfile()}
                ></button>
              )}
            </div>
            <div className="offcanvas-body">
              <Profile
                key={"offcanvas-profile"}
                token={token}
                user={user}
                edit={location?.state?.user}
              />
            </div>
          </div>
        ) : (
          <div className="modal" id="profileModal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
              <div
                className="modal-content"
                style={{
                  backdropFilter: "blur(30px) !important",
                  background: "rgb(255, 255, 255, 0.85)",
                  height: "43%",
                }}
              >
                <div className="modal-header">
                  {!isNewUser && (
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => closeProfile()}
                    ></button>
                  )}
                </div>
                <div className="modal-body">
                  <Profile
                    key={"modal-profile"}
                    token={token}
                    user={user}
                    edit={location?.state?.user}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {loading && (
          <div
            style={{
              backgroundColor: "teal",
              padding: "10px",
              color: "white",
              position: "fixed",
              width: "100vw",
              zIndex: "9",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <div
              className="container"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Uploading Files</span>
              <div
                style={{
                  height: "20px",
                  backgroundColor: "#ddd",
                  borderRadius: "5px",
                  flex: "1",
                  marginRight: "20px",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    width: `${fileUploadProgress}%`,
                    height: "100%",
                    backgroundColor: "yellow",
                    borderRadius: "5px",
                  }}
                ></div>
              </div>
              <span style={{ marginRight: "35px" }}>{fileUploaded}</span>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  window.location.reload();
                }}
              >
                X
              </button>
            </div>
          </div>
        )}
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(e) => handleFileUpload(e)}
          id="fileInput"
          multiple={true}
          accept="video/*,image/png,image/jpeg,image/heic,image/webp"
        />
        {images.length === 0 &&
          !isFetching &&
          !isUploading &&
          initialLoaded && (
            <h2 className="text-center m-5 text-white">
              No images/videos found
            </h2>
          )}

        {!phone && (
          <img
            src={plus}
            style={{
              position: "fixed",
              zIndex: 100,
              bottom: 50,
              cursor: "pointer",
              width: 50,
              height: 50,
              right: 30,
            }}
            alt="plus"
            onClick={UploadImages}
          />
        )}

        <div className="container">
          {enableSelect && (
            <div
              style={{
                zIndex: 9,
                background: "#1c1c1c",
                padding: "10px 0",
                position: "fixed",
                width: "100%",
              }}
            >
              {/* <button
              className="btn btn-sm btn-primary text-white font-bold rounded me-2"
              onClick={() => {
                selectAllImages();
              }}
            >
              Select All
            </button> */}
              <button
                className="btn btn-sm btn-outline-secondary bg-white text-black font-bold rounded  me-2"
                onClick={() => {
                  setEnableSelect(!enableSelect);
                  setSelectMultiImages([]);
                }}
              >
                Clear
              </button>
              <button
                className="btn btn-sm btn-danger rounded"
                disabled={selectMultiImages.length === 0}
                onClick={() => deleteImages()}
              >
                <img src={deleteIcon} width={16} alt="delete icon" />
                {selectMultiImages.length > 0 &&
                  `(${selectMultiImages.length})`}
              </button>
            </div>
          )}
        </div>

        <div
          className={`container`}
          style={{ marginTop: enableSelect ? "3.5rem" : "1rem" }}
        >
          <div className="row p-2 pt-0">
            {images.map((image, index) => (
              <div
                className={`col-${multiList === "yes" ? "4" : "12"} col-xs-${
                  multiList === "yes" ? "2" : "12"
                }  col-md-${multiList === "yes" ? "4" : "12"} col-lg-${
                  multiList === "yes" ? "3" : "12"
                } mt-1 mb-1 p-1`}
                key={`${image._id}_${index}`}
                id={
                  index + 1 === images.length && hasMore
                    ? `last-element${images.length}`
                    : ""
                }
              >
                <div
                  style={{
                    aspectRatio: 1,
                    position: "relative",
                  }}
                >
                  {enableSelect && (
                    <div
                      style={{
                        zIndex: 1,
                        position: "absolute",
                        top: "-10px",
                        right: 0,
                        cursor: "pointer",
                        borderRadius: "4px",
                        backgroundColor: "",
                        margin: "auto",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        checked={selectMultiImages.includes(image.image_name)}
                        onChange={() => toggleImageSelection(image.image_name)}
                      />
                    </div>
                  )}

                  {image.type && multiList !== "yes" && (
                    <LazyImage
                      src={image.thumbnailMediumPath || image.thumbnailPath}
                      className="img-fluid rounded w-100 h-100"
                      onClick={
                        !enableSelect
                          ? () => handleClick(index)
                          : () => toggleImageSelection(image.image_name)
                      }
                      alt={image.image_name}
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      image={image}
                    />
                  )}

                  {image.type && multiList === "yes" && (
                    <LazyImage
                      src={image.thumbnailPath}
                      className="img-fluid rounded w-100 h-100"
                      onClick={
                        !enableSelect
                          ? () => handleClick(index)
                          : () => toggleImageSelection(image.image_name)
                      }
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      image={image}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>

          {!initialLoaded && <LoadingSpinner />}
          {isFetching && <LoadingSpinner />}

          {images[selectedImage] && (
            <DownloadModal
              key={`mdl-${selectedImage}`}
              selectedImage={selectedImage}
              images={images}
              setSelectedImage={setSelectedImage}
              goNext={goNext}
              goPrev={goPrev}
            />
          )}
        </div>
      </div>
      <CopyRight />
    </>
  );
}
