import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import wed_snap from "../assets/wedsnap.png";
import countries from "../assets/country-list.json";
import Select from "react-select";
import "./login.css";
import CopyRight from "../components/CopyRight";

const countryOptions = countries.map((country) => ({
  value: country.dial_code,
  label: `${country.dial_code} ${country.code}`,
}));
export default function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [selectedCountry, setSelectedCountry] = useState({
    value: "+1",
    label: "+1",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[0-9\b]+$/;
    if (inputValue === "" || regex.test(inputValue)) {
      // Limit length to 10 characters
      if (inputValue.length <= 10) {
        setPhoneNumber(inputValue);
      }
    }
  };
  const changeOTP = (e) => {
    const inputValue = e.target.value;
    const regex = /^[0-9\b]+$/;
    if (inputValue === "" || regex.test(inputValue)) {
      // Limit length to 10 characters
      if (inputValue.length <= 6) {
        setOtp(inputValue);
      }
    }
  };

  const handleCountryChange = (selectedCountry) => {
    setSelectedCountry({
      value: selectedCountry.value,
      label: selectedCountry.value,
    });
  };
  const goBack = () => {
    setOtpError("");
    setOtp("");
    setOtpSent(false);
  };

  const handleSendOTP = (e) => {
    e.preventDefault();
    console.log(phoneNumber);
    if (phoneNumber.trim() === "") {
      setPhoneNumberError("Please enter a phone number");
      return;
    } else if (phoneNumber.trim().length < 10) {
      setPhoneNumberError("Phone number should have a 10 digits.");
      return;
    } else {
      setPhoneNumberError("");
      setLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          phoneNumber: selectedCountry.value + phoneNumber,
        }),
      };

      fetch(`${process.env.REACT_APP_API_URL}/send-sms`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          let token = data.token;
          setUser(data.user);
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(data.user));

          if (data.user.name) {
            navigate("/gallery");
          } else {
            navigate("/gallery?join=1", {
              state: { token, user: data.user },
            });
          }
          // Handle success response here
        })
        .catch((error) => {
          console.error("Error:", error);
          // alert("Something went wrong");
          // Handle error here
        })
        .finally(() => {
          setLoading(false);
        });

      // Clear error message if any
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      JSON.parse(localStorage.getItem("user")).name
    ) {
      navigate("/gallery");
    } else {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  return (
    <>
      <section className="bg-white">
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center">
            <div
              className="col-md-5 col-lg-4 col-xl-4 col-sm-12 text-center"
              style={{
                backgroundColor: "#f9f9f9",
                borderRadius: "5px",
                border: "1px solid #dcdcdc",
                padding: 0,
              }}
            >
              <img
                src={wed_snap}
                className="img-fluid pt-3"
                alt="logo"
                style={{ maxHeight: "100px" }}
              />
              <br />
              <br />
              <div style={{ position: "relative", top: 10 }}>
                <div
                  style={{
                    fontFamily: "Qedysans",
                    width: "100%",
                    fontSize: "24px",
                    color: "white",
                    backgroundColor: "#1565C0",
                  }}
                >
                  {process.env.REACT_APP_WEDDING_NAME}
                </div>

                <br />
                <p
                  style={{
                    fontFamily: "Qedysans",
                    color: "#0d6efd",
                    fontWeight: "bold",
                  }}
                >
                  Please share wedding pic with us.
                </p>
                <div style={{ paddingInline: 12 }}>
                  {!otpSent ? (
                    <form onSubmit={handleSendOTP}>
                      {phoneNumberError && (
                        <div className="alert alert-danger" role="alert">
                          {phoneNumberError}
                        </div>
                      )}

                      {/* <p>Please enter your mobile number.</p> */}
                      <div className="input-group mb-3">
                        <Select
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          options={countryOptions}
                          styles={{
                            container: (baseStyles) => ({
                              ...baseStyles,
                              width: "31%",
                            }),
                          }}
                        />
                        <input
                          type="tel"
                          id="form1Example13"
                          className="form-control"
                          value={phoneNumber}
                          onChange={handleChange}
                          style={{ width: "60%" }}
                          maxLength={10}
                          placeholder="Enter your mobile number"
                        />
                      </div>
                      <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-lg btn-primary  btn-block mb-3"
                      >
                        {loading ? "Logging In" : "Login"}
                      </button>
                    </form>
                  ) : (
                    <form>
                      {otpError && (
                        <div className="alert alert-danger" role="alert">
                          {otpError}
                        </div>
                      )}

                      <div className="form-outline ">
                        <label className="form-label">
                          Enter OTP sent to {selectedCountry.value}
                          {phoneNumber}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={otp}
                          onChange={changeOTP}
                        />
                      </div>
                      <span>
                        {" "}
                        Isn't {selectedCountry.value}
                        {phoneNumber} your number?{" "}
                        <a onClick={goBack}>Change Number</a>{" "}
                      </span>
                      <br />
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mt-3 mb-3"
                      >
                        Verify OTP
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CopyRight />
    </>
  );
}
