import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export function showAlert(message, buttons = []) {
  confirmAlert({
    title: message,
    buttons:
      buttons.length > 0
        ? buttons
        : [
            {
              label: "Ok",
              onClick: () => {
                return;
              },
            },
          ],
  });
}
