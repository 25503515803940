import React from "react";

function CopyRight() {
  // Check if the environment variable matches "Varun"
  const showFooter = process.env.REACT_APP_WEDDING_NAME == "Varun & Priyanka";

  // If showFooter is false, return null (render nothing)
  if (!showFooter) return null;

  return (
    <footer
      className="d-flex justify-content-center p-1 w-100 z-3"
      style={{ position: "fixed", bottom: 0, backgroundColor: "#1b1b1b" }}
    >
      <div className="w-100 text-center">
        <small className="text-white">
          Powered by:{" "}
          <a
            href="https://www.sndkcorp.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
          >
            www.sndkcorp.com
          </a>{" "}
          (Brijesh Patel)
        </small>
      </div>
    </footer>
  );
}

export default CopyRight;
