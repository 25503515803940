import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DownloadModal from "../components/DownloadModal";
import BackButton from "../components/BackButton";
import loadingGif from "../assets/loading-gif.gif";
import SelectIcon from "../assets/icons/select.svg";
import DownloadIcon from "../assets/icons/download.svg";
import useSize from "../hooks/useSize";
import { downloadImage } from "../utils/download-image";
import CopyRight from "../components/CopyRight";

export default function AISearches() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);
  let [users, setUsers] = useState([]);
  const location = useLocation();
  const searchUser = location?.state?.user;
  const navigate = useNavigate();
  const isMobile = useSize();
  const [enableSelect, setEnableSelect] = useState(false);
  const [selectMultiImages, setSelectMultiImages] = useState([]);

  const toggleImageSelection = (image_name) => {
    if (selectMultiImages.includes(image_name)) {
      setSelectMultiImages(
        selectMultiImages.filter((image) => image !== image_name)
      );
    } else {
      setSelectMultiImages([...selectMultiImages, image_name]);
    }
  };

  const searchImages = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/ai-search/get-searches`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ai_id: searchUser?._id,
          }),
        }
      );

      const data = await response.json();

      // Update the state with image links
      setIsProcessing(!data.is_processed);
      // setUsers((prevImages) => [...prevImages, ...data.data]);
      setUsers(data.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        searchImages();
        if (!isProcessing) {
          clearInterval(interval);
        }
      } catch (error) {
        console.log("error", error);
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [isProcessing]);

  const goPrev = () => {
    if (users.length > selectedImage && selectedImage >= 1) {
      setSelectedImage(selectedImage - 1);
    }
  };

  const goNext = () => {
    if (users.length > selectedImage) {
      setSelectedImage(selectedImage + 1);
    }
  };

  const handleClick = (index) => {
    setSelectedImage(index);
  };

  const handleDownload = async () => {
    // Download all selected images
    selectMultiImages.forEach(async (image) => {
      await downloadImage(image)
        .then(() => {
          console.log("downloaded");
          setSelectMultiImages([]);
          setEnableSelect(false);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  return (
    <>
      <div className="container">
        <div
          className="navbar navbar-light justify-content-between px-2 position-sticky top-0 z-3 "
          style={{ backgroundColor: "#1b1b1b" }}
        >
          <BackButton />

          <div
            onClick={() => {
              setEnableSelect(!enableSelect);
              setSelectMultiImages([]);
            }}
            className="cursor-pointer"
          >
            <img src={SelectIcon} alt="select icon" width={"25"} />
            {!isMobile && <span className="text-white ms-1">Select</span>}
          </div>

          {selectMultiImages.length > 0 && (
            <div className="cursor-pointer" onClick={handleDownload}>
              <img src={DownloadIcon} alt="download icon" width={"25"} />
              {!isMobile && <span className="text-white ms-1">Download</span>}
            </div>
          )}

          <div className="circle" style={{ cursor: "pointer" }}>
            <img
              src={
                searchUser?.thumbnailPath
                  ? searchUser?.thumbnailPath
                  : searchUser?.imagePath
              }
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              alt="profile"
            />
          </div>
        </div>

        {isProcessing && (
          <>
            <div className="text-center">
              <img
                src={loadingGif}
                alt="Loading icon"
                width={250}
                height={100}
                className="img-fluid"
                style={{ margin: "auto" }}
              />
              <h2 className="text-center text-white">
                Sit tight, our AI is on a photo-finding mission!
              </h2>
            </div>
          </>
        )}
        {users.length == 0 && !isLoading && !isProcessing && (
          <h2 className="text-center m-5 text-white">No matches found</h2>
        )}
        <div
          className={`row row-cols-3 row-cols-sm-3 row-cols-xs-3 row-cols-md-4 row-cols-lg-4 g-1 my-4`}
          style={{ position: "relative" }}
        >
          {users.map((image, index) => (
            <div key={index}>
              <div
                className="aspect-ratio-container"
                style={{
                  paddingBottom: "80%",
                  position: "relative",
                  backgroundColor: "#f0f0f0",
                  overflowBlock: "hidden",
                }}
              >
                {enableSelect && (
                  <div
                    style={{
                      zIndex: 1,
                      position: "absolute",
                      top: "-10px",
                      right: 0,
                      cursor: "pointer",
                      borderRadius: "4px",
                      backgroundColor: "",
                      margin: "auto",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                      checked={selectMultiImages.includes(image.imagePath)}
                      onChange={() => toggleImageSelection(image.imagePath)}
                    />
                  </div>
                )}

                {image && (
                  <img
                    src={image.thumbnailPath}
                    onClick={() => {
                      if (enableSelect) {
                        toggleImageSelection(image.imagePath);
                      } else {
                        handleClick(index);
                      }
                    }}
                    className="position-absolute top-0 start-0 w-100 h-100"
                    alt={`user - ${index}`}
                    style={{
                      cursor: "pointer",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        {users[selectedImage] && (
          <DownloadModal
            key={`aimdl-${selectedImage}`}
            selectedImage={selectedImage}
            images={users}
            setSelectedImage={setSelectedImage}
            goNext={goNext}
            goPrev={goPrev}
          />
        )}
      </div>
      <CopyRight />
    </>
  );
}
